import Grid from "@mui/material/Grid";
import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

const Comp = (props) => {
  return (
    <Grid
      item
      md={6}
      className={props.totalePersRistorazione > 0 ? "" : "hideOnPrint"}
      style={props.style}
    >
      <div className="bloccoCalcoloPrev">
        <Grid container spacing={1}>
          <Grid item xs={6} className="alignCenter">
            <label className="labelGruppo noBorder">Extra ristorazione</label>
          </Grid>
          <Grid item xs={3}>
            <TextField
              size="small"
              label="Quota a pagante"
              fullWidth
              disabled
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              className="inputCustom totalePaganti euro"
              value={props.totalePersRistorazione}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              size="small"
              label="Quota totale"
              disabled
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              className="inputCustom totaleComplessivo euro"
              value={props.arrotonda(
                (props.totalePersRistorazione || 0) * props.totalePaganti
              )}
            />
          </Grid>
          <Grid item md={12} xs={12} className="hideOnPrint">
            <div className="labelGruppoCont"></div>
          </Grid>
          {props.prezziRistorazione.map((dati, index) => {
            if (dati.quantita === undefined) {
              dati.quantita = 0;
            }
            if (dati.gratuita === undefined) {
              dati.gratuita = 0;
            }

            //prezzo calcolato
            let prezzoCalcolato = 0;
            if (dati.priceKey.includes("_pax_")) {
              let giorniNotti = props.giorni;
              if (dati.priceKey.includes("_night_")) {
                giorniNotti = props.notti;
              }
              prezzoCalcolato = Number(
                giorniNotti * dati.prezzo * dati.quantita
              );
            } else if (dati.priceKey.includes("_meal_")) {
              prezzoCalcolato = Number(
                (props.totaleSenzaAutisti -
                  Math.floor(
                    props.totaleSenzaAutisti /
                      (dati.gratuita != 0 ? Number(dati.gratuita) + 1 : 9999)
                  )) *
                  dati.prezzo *
                  dati.quantita
              );
            }

            dati.totale = prezzoCalcolato;

            return (
              <React.Fragment key={index}>
                <Grid item xs={12}>
                  <div
                    className="rigaFlexIngressi"
                    style={{
                      padding: "7px 2px 2px",
                      backgroundColor:
                        dati.quantita > 0 ? "#42a5da3b" : "transparent",
                    }}
                  >
                    <div
                      style={{
                        minWidth: 200,
                      }}
                    >
                      {dati.description}
                    </div>

                    <TextField
                      size="small"
                      label={
                        dati.priceKey.includes("_pax_") ? "N. pax" : "N. pasti"
                      }
                      type="number"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      className="inputCustom"
                      value={dati.quantita || ""}
                      onChange={(e) => {
                        /* funzione per mappare il valore inserito */
                        props.settaDatiPrezziRist(
                          props.prezziRistorazione,
                          "quantita",
                          index,
                          e.target.value
                        );
                        // console.log(dati);
                      }}
                    />

                    <TextField
                      size="small"
                      label="Prezzo"
                      type="number"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      className="inputCustom euro"
                      value={dati.prezzo || ""}
                      onChange={(e) => {
                        props.settaDatiPrezziRist(
                          props.prezziRistorazione,
                          "prezzo",
                          index,
                          e.target.value
                        );
                      }}
                    />

                    {dati.priceKey.includes("_meal_") && (
                      <>
                        {/* gratuita extra ristorazione ----------------- */}
                        <Autocomplete
                          value={String(dati.gratuita) || ""}
                          style={{ minWidth: 75 }}
                          freeSolo
                          onInputChange={(e, value) => {
                            props.settaDatiPrezziRist(
                              props.prezziRistorazione,
                              "gratuita",
                              index,
                              value
                            );
                          }}
                          options={props.optHotel.gratuita || []}
                          disableClearable
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              size="small"
                              label="Gratuità"
                              className="inputCustom"
                            />
                          )}
                        />
                      </>
                    )}
                    <TextField
                      size="small"
                      label="Quota a pagante"
                      fullWidth
                      disabled
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      className="inputCustom euro"
                      value={
                        props.arrotonda(dati.totale / props.totalePaganti) || 0
                      }
                    />
                    <TextField
                      size="small"
                      label="Totale"
                      fullWidth
                      disabled
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      className="inputCustom euro"
                      value={props.arrotonda(dati.totale) || 0}
                    />
                  </div>
                </Grid>
              </React.Fragment>
            );
          })}
        </Grid>
      </div>
    </Grid>
  );
};

export default Comp;
