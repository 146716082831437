import { monFetch, monForm, monMedia } from "../inc/inc";

export default async function queryPreventivo(parametri) {
  parametri.setInCaricamento((inCaricamento) => inCaricamento + 1);
  const res = await monFetch("/quoteGet", {
    id: parametri.id,
    fromCalcolo: true,
  });

  const prev = res.quote;
  // parametri.setInsegnantiNonPaganti(prev.free_teacher);
  // parametri.setGenitoriNonPaganti(prev.free_parents);

  // parametri.setGenitoriPaganti(prev.paying_parents);
  // parametri.setInsegnantiPaganti(prev.paying_teacher);
  // parametri.setStudenti(prev.students);
  // parametri.setStudentiConDisabilita(prev.w_disability);
  if (prev.transportIds.length == 0) {
    //null se solo hotel
    prev.transportIds = null;
  }
  parametri.setTipoViaggio(prev.trip_type_id);
  parametri.setMezziTrasportoPrev(prev.transportIds);
  parametri.setNumeroPreventivo(prev.code);
  parametri.setNumeroPreventivoKreo(prev.code_kreosoft);
  parametri.setDestinazione(prev.destination);
  parametri.setStatoPreventivo(prev.status_id)

  let totalePaganti =
    prev.paying_parents +
    prev.paying_teacher +
    prev.students +
    prev.w_disability;
  parametri.setTotalePaganti(totalePaganti);
  parametri.setTotaleNonPaganti(prev.free_teacher + prev.free_parents);
  //di default metto i non paganti in singola
  parametri.setGratuitiInSingola(prev.free_teacher + prev.free_parents);
  parametri.setGiorni(prev.days);
  parametri.setNotti(prev.days - 1);
  parametri.setPartenza(prev.start_date);
  parametri.setRientro(prev.end_date);
  parametri.setAddetto((prev.first_name || "") + " " + (prev.last_name || ""));
  parametri.setAddettoConferma((prev.first_name_conf || "") + " " + (prev.last_name_conf || ""));

  parametri.setAddettoRiquotazione((prev.first_name_riq || "") + " " + (prev.last_name_riq || ""));

  parametri.setNoteBus(prev.bus_notes);
  parametri.setRisposteBus(prev.bus_response);

  parametri.queryMezziTrasporto(prev.transportIds);
  parametri.setInCaricamento((inCaricamento) => inCaricamento - 1);
  parametri.setIstituto(prev.companyName)
}
