import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";

const Comp = (props) => {
  //stato interno (righe bus)
  const [datiBusInterno, setDatiBusInterno] = useState([]);
  const [timeoutUpdate, setTimeoutUpdate] = useState(null);
  const [totale, setTotale] = useState(0);

  const settaDatiBusInterno = (index, key, value) => {
    let temp = [...datiBusInterno];
    temp[index][key] = value;

    //totale
    temp[index].totale =
      (temp[index].parcheggi || 0) +
      (temp[index].ztl || 0) +
      (temp[index].costo || 0) +
      (temp[index].costo2Autista || 0);
    // console.log("BUS", temp);
    setDatiBusInterno(temp);
  };

  const arrotonda = (valore) => {
    return Math.round(valore * 100) / 100;
  };

  useEffect(() => {
    //aggiungo e rimuovo bus
    // console.log("BUS", "EFF",props.numeroBus);
    let temp = [...datiBusInterno];
    if (props.numeroBus >= temp.length) {
      for (let i = temp.length; i < props.numeroBus; i++) {
        // console.log("BUS", i);
        temp.push({ n_autisti: 1 });
      }
    } else {
      for (let i = temp.length; i >= props.numeroBus; i--) {
        // console.log("BUS", i);
        temp.splice(i, 1);
      }
    }
    setDatiBusInterno(temp);
  }, [props.numeroBus]);

  useEffect(() => {
    ///inizializzo dati
    // console.log("BUS", "EFF2", props.datiBus);
    setDatiBusInterno(props.datiBus);
  }, [props.datiBus]);

  useEffect(() => {
    clearTimeout(timeoutUpdate);
    setTimeoutUpdate(
      setTimeout(() => {
        ///al cambio dei dati interni aggiorno i globali
        //totale pers
        let totaleBus = 0;
        datiBusInterno.forEach((el) => {
          totaleBus += el.totale || 0;
        });
        let totalePers = arrotonda(
          (totaleBus + props.spesePasti) / props.totalePaganti
        );
        // console.log("dati bus 2", datiBusInterno)
        props.setDatiBus(datiBusInterno);
        setTotale(totaleBus + props.spesePasti);
        props.setTotalePersBus(totalePers);

        /////calcolo numero autisti globale
        let nAutisti = 0;
        datiBusInterno.forEach((el) => {
          nAutisti += Number(el.n_autisti) || 0;
        });
        props.setAutisti(nAutisti);
        // }
      }, 200)
    );
  }, [datiBusInterno, props.spesePasti, props.totalePaganti]);

  return (
    <Grid container spacing={1} paddingTop="8px">
      <Grid item sm={12}>
        <div className="bloccoCalcoloPrev">
          <Grid container spacing={1}>
            <Grid item sm={12} xs={12}>
              <label className="labelGruppo">Bus</label>
            </Grid>
            <Grid item sm={1}>
              <TextField
                size="small"
                label="Nr. bus"
                type="number"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                className="inputCustom"
                value={props.numeroBus}
                onWheel={(e) => e.target.blur()}
                onChange={(e) => {
                  props.setNumeroBus(e.target.value);
                }}
              />
            </Grid>
            <Grid item sm={1}>
              <TextField
                size="small"
                label="Pasti"
                type="text"
                disabled
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                className="inputCustom"
                value={props.spesePasti}
              />
            </Grid>
            <Grid item sm={1}>
              <TextField
                size="small"
                label="Quota a pagante"
                type="text"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                disabled
                variant="outlined"
                className="inputCustom totalePaganti  euro"
                value={props.totalePersBus}
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                size="small"
                label="Quota totale"
                disabled
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                className="inputCustom totaleComplessivo euro"
                value={totale}
              />
            </Grid>
            {datiBusInterno.map((el, index) => {
              return (
                <Grid item sm={12} key={index} className="rigaBus">
                  <TextField
                    size="small"
                    label="Posti a sedere"
                    type="number"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    className="inputCustom"
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => {
                      settaDatiBusInterno(index, "posti", e.target.value);
                    }}
                    value={el.posti || ""}
                  />
                  <TextField
                    size="small"
                    label="N.Autisti"
                    type="number"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    className="inputCustom"
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => {
                      settaDatiBusInterno(index, "n_autisti", e.target.value);
                    }}
                    value={el.n_autisti || ""}
                  />
                  <TextField
                    size="small"
                    label="Parcheggi"
                    type="number"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    className="inputCustom euro"
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => {
                      settaDatiBusInterno(
                        index,
                        "parcheggi",
                        Number(e.target.value)
                      );
                    }}
                    value={el.parcheggi || ""}
                  />
                  <TextField
                    size="small"
                    label="Ztl"
                    type="number"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    className="inputCustom euro"
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => {
                      settaDatiBusInterno(index, "ztl", Number(e.target.value));
                    }}
                    value={el.ztl || ""}
                  />
                  <TextField
                    size="small"
                    label="Costo Bus"
                    type="number"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    className="inputCustom euro"
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => {
                      settaDatiBusInterno(
                        index,
                        "costo",
                        Number(e.target.value)
                      );
                    }}
                    value={el.costo || ""}
                  />
                  <TextField
                    size="small"
                    label="2° autista di spinta"
                    type="number"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    className="inputCustom euro"
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => {
                      settaDatiBusInterno(
                        index,
                        "costo2Autista",
                        Number(e.target.value)
                      );
                    }}
                    value={el.costo2Autista || ""}
                  />
                  <TextField
                    size="small"
                    label="Autonoleggio"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    className="inputCustom"
                    onChange={(e) => {
                      settaDatiBusInterno(
                        index,
                        "autonoleggio",
                        e.target.value
                      );
                    }}
                    value={el.autonoleggio || ""}
                  />
                  <TextField
                    size="small"
                    label="Contatto autista"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    className="inputCustom"
                    onChange={(e) => {
                      settaDatiBusInterno(index, "autista", e.target.value);
                    }}
                    value={el.autista || ""}
                  />
                  <TextField
                    size="small"
                    label="Targa"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    className="inputCustom"
                    onChange={(e) => {
                      settaDatiBusInterno(index, "targa", e.target.value);
                    }}
                    value={el.targa || ""}
                  />
                </Grid>
              );
            })}
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};

export default Comp;
