import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {
  monFetch,
  // settaCookieFiltri,
  // getValoreCookieFiltri,
} from "../inc/inc";
import { useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CopyIcon from "@mui/icons-material/CopyAll";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Pagination from "@mui/material/Pagination";

const Comp = (props) => {
  let location = useLocation();

  const [programmi, setProgrammi] = useState([]);
  const [programmiPDF, setProgrammiPDF] = useState([]);

  const [popupElimina, setPopupElimina] = useState(false);
  const [daEliminare, setDaEliminare] = useState(null);

  const [pagina, setPagina] = useState(1);
  const [risPerPagina, setRisPerPagina] = useState(50);
  const [totaleRis, setTotaleRis] = useState(50);

  const [filtroRegione, setFiltroRegione] = useState(null);
  const [filtroCitta, setFiltroCitta] = useState(props.destinazione || null);
  const [filtroDescrizione, setFiltroDescrizione] = useState(null);

  const [inputRegione, setInputRegione] = useState("");
  const [inputDestinazione, setInputDestinazione] = useState("");
  const [inputDescrizione, setInputDescrizione] = useState("");
  const [inputMaxPax, setInputMaxPax] = useState("");
  const [inputEuroGruppo, setInputEuroGruppo] = useState("");
  const [inputEuroPax, setInputEuroPax] = useState("");
  const [inputId, setInputId] = useState(0);

  const [apriBoxNuovo, setApriBoxNuovo] = useState(false);

  const aggiungiRimuoviSelezionati = (id, arr, checked) => {
    let temp = [...props.elementiSelezionati];
    if (checked) {
      //aggiungo obj all'array
      temp.push(arr);
    } else {
      //rimuovo obj dall'array
      let obj = temp.find((el) => {
        return el.id == id;
      });

      let index = temp.indexOf(obj);
      if (index >= 0) {
        temp.splice(index, 1);
      }
    }
    props.setElementiSelezionati(temp);
    console.log(temp);
  };

  const selezionaTutti = (checked) => {
    let temp = [...props.elementiSelezionati];
    programmi.forEach((p) => {
      if (checked) {
        //aggiungo obj all'array
        temp.push(p);
      } else {
        //rimuovo obj dall'array
        let obj = temp.find((el) => {
          return el.id == p.id;
        });

        let index = temp.indexOf(obj);
        if (index >= 0) {
          temp.splice(index, 1);
        }
      }
    });
    props.setElementiSelezionati(temp);
  };

  async function query(pagina = 1, perPdf = false, inseriti = false) {
    const res = await monFetch("/destinationEscursioniList", {
      per_pdf: perPdf,
      inseriti: inseriti,
      idInseriti: props.idInseriti ? props.idInseriti : null,
      idInseritiPDF: props.idInseritiPDF ? props.idInseritiPDF : null,
      page: pagina,
      destination: !inseriti ? filtroCitta : null,
      description: !inseriti ? filtroDescrizione : null,
      region: !inseriti ? filtroRegione : null,
      idProposta: props.idProposta
    });
    if (!inseriti) {
      setProgrammi(res.plan);
    } else {
      setProgrammiPDF(res.plan);
    }
    setTotaleRis(res.count);
    setRisPerPagina(res.resPerPage);
  }

  async function salvaNuovo() {
    const res = await monFetch("/destinationEscursioniSet", {
      region: inputRegione,
      destination: inputDestinazione,
      description: inputDescrizione,
      max_passenger: inputMaxPax,
      group_price: inputEuroGruppo,
      passenger_price: inputEuroPax,
      id: inputId || null,
    });
    if (props.inserisciExtraPDF) {
      query(1, true);
      query(1, true, true); //query per i completi
    } else {
      query(1);
    }
    setInputRegione("");
    setInputId(0);
    setInputDestinazione("");
    setInputDescrizione("");
    setInputMaxPax("");
    setInputEuroGruppo("");
    setInputEuroPax("");
    setApriBoxNuovo(false);
  }

  async function elimina(id) {
    const res = await monFetch("/destinationEscursioniDelete", { id });
    setPopupElimina(false);
    if (props.inserisciExtraPDF) {
      query(1, true);
      query(1, true, true); //query per i completi
    } else {
      query(1);
    }
  }

  useEffect(() => {
    if (!props.setElementiBarraTop) {
      return;
    }
    let barraTop = (
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h2>
          <a href="/dest_escursioni" style={{ color: "#fff" }}>
            Escursioni Viaggi
          </a>
        </h2>
        <Button
          className="inputCustom"
          variant="contained"
          onClick={(e) => {
            setInputRegione("");
            setInputId(0);
            setInputDestinazione("");
            setInputDescrizione("");
            setInputMaxPax("");
            setInputEuroGruppo("");
            setInputEuroPax("");
            setApriBoxNuovo(!apriBoxNuovo);
          }}
        >
          {!apriBoxNuovo ? "Nuovo" : "Annulla"}
        </Button>
      </div>
    );
    props.setElementiBarraTop(barraTop);
  }, [apriBoxNuovo]);

  useEffect(() => {
    if (props.inserisciExtraPDF) {
      query(pagina, true);
      query(pagina, true, true); //query per i completi
    } else {
      query(pagina);
    }
  }, [pagina, props.idInseriti]);

  useEffect(() => {
    // if (props.setElementiSelezionati) {
    //   props.setElementiSelezionati([]);
    // }
    if (
      filtroRegione === null &&
      filtroCitta === null &&
      filtroDescrizione === null
    ) {
      return;
    }
    if (pagina != 1) {
      setPagina(1);
    } else {
      if (props.inserisciExtraPDF) {
        query(1, true); //PDF
      } else {
        query(1);
      }
    }
  }, [filtroCitta, filtroDescrizione, filtroRegione]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={3} xs={12}>
          {/* <h2 style={{ margin: 0 }}>Aggiungi nuovo</h2> */}
        </Grid>
        <Grid item sm={3} xs={12}>
          <TextField
            size="small"
            label="Cerca Regione/Stato"
            fullWidth
            variant="outlined"
            className="inputCustom"
            value={filtroRegione || ""}
            onChange={(e) => {
              setFiltroRegione(e.target.value.toUpperCase());
            }}
          />
        </Grid>
        <Grid item sm={3} xs={12}>
          <TextField
            size="small"
            label="Cerca Città"
            fullWidth
            variant="outlined"
            className="inputCustom"
            value={filtroCitta || ""}
            onChange={(e) => {
              setFiltroCitta(e.target.value.toUpperCase());
            }}
          />
        </Grid>
        <Grid item sm={3} xs={12}>
          <TextField
            size="small"
            label="Cerca Descrizione"
            fullWidth
            variant="outlined"
            className="inputCustom"
            value={filtroDescrizione || ""}
            onChange={(e) => {
              setFiltroDescrizione(e.target.value.toUpperCase());
            }}
          />
        </Grid>
        {/* form nuovo */}
      </Grid>
      <div
        className="boxNuovaDest"
        style={{ display: apriBoxNuovo ? "flex" : "none" }}
      >
        <TextField
          label="Regione/Stato"
          size="small"
          fullWidth
          variant="outlined"
          className="inputCustom"
          value={inputRegione}
          onChange={(e) => {
            setInputRegione(e.target.value);
          }}
        />
        <TextField
          label="Città"
          size="small"
          fullWidth
          variant="outlined"
          className="inputCustom"
          value={inputDestinazione}
          onChange={(e) => {
            setInputDestinazione(e.target.value);
          }}
        />
        <TextField
          label="Descrizione"
          size="small"
          fullWidth
          variant="outlined"
          className="inputCustom"
          value={inputDescrizione}
          onChange={(e) => {
            setInputDescrizione(e.target.value);
          }}
        />
        <TextField
          label="Max Pax"
          size="small"
          type="number"
          fullWidth
          variant="outlined"
          className="inputCustom"
          value={inputMaxPax}
          onChange={(e) => {
            setInputMaxPax(e.target.value);
          }}
        />
        <TextField
          label="A Gruppo"
          size="small"
          type="number"
          fullWidth
          variant="outlined"
          className="inputCustom"
          value={inputEuroGruppo}
          onChange={(e) => {
            setInputEuroGruppo(e.target.value);
          }}
        />
        <TextField
          label="A Pax"
          size="small"
          type="number"
          fullWidth
          variant="outlined"
          className="inputCustom"
          value={inputEuroPax}
          onChange={(e) => {
            setInputEuroPax(e.target.value);
          }}
        />
        <Button
          fullWidth
          className="inputCustom"
          variant="contained"
          onClick={(e) => {
            if (!inputDescrizione) {
              return;
            }
            salvaNuovo();
          }}
        >
          {inputId == 0 ? "Aggiungi" : "Modifica"}
        </Button>
      </div>

      {programmiPDF.length > 0 && (
        <div style={{ maxHeight: 300, overflow: "auto", marginTop: 5 }}>
          <Table className="tabellaAggiunti">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                {/* <TableCell>Regione/Stato</TableCell>
                <TableCell>Città</TableCell> */}
                <TableCell>Descrizione</TableCell>
                <TableCell>Max Pax</TableCell>
                <TableCell>A Gruppo</TableCell>
                <TableCell>A Pax</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {programmiPDF.map((p) => {
                return (
                  <TableRow key={p.id} className="">
                    <TableCell>
                      <DeleteIcon
                        color="error"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          props.eliminaInseritoPDF(p.id);
                          if (props.inserisciExtraPDF) {
                            query(1, true);
                            query(1, true, true); //query per i completi
                          } else {
                            query(1);
                          }
                        }}
                      />
                    </TableCell>
                    {/* <TableCell>{p.region}</TableCell>
                    <TableCell>{p.destination}</TableCell> */}
                    <TableCell>{p.description}</TableCell>
                    <TableCell>{p.max_passenger}</TableCell>
                    <TableCell>{p.group_price}</TableCell>
                    <TableCell>{p.passenger_price}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      )}
      <Table>
        <TableHead>
          <TableRow>
            {props.selezionabile && (
              <TableCell>
                {" "}
                <input
                  type="checkbox"
                  onChange={(e) => {
                    selezionaTutti(e.target.checked);
                  }}
                ></input>
              </TableCell>
            )}
            <TableCell>Regione</TableCell>
            <TableCell>Destinazione</TableCell>
            <TableCell>Descrizione</TableCell>
            <TableCell>Max Pax</TableCell>
            <TableCell>A Gruppo</TableCell>
            <TableCell>A Pax</TableCell>
            {!props.selezionabile && <TableCell></TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {programmi.map((p) => {
            return (
              <TableRow key={p.id} className="">
                {props.selezionabile && (
                  <TableCell style={{ width: 50 }}>
                    <input
                      type="checkbox"
                      checked={
                        props.elementiSelezionati.find((el) => {
                          return el.id == p.id;
                        })
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        aggiungiRimuoviSelezionati(p.id, p, e.target.checked);
                      }}
                    ></input>
                  </TableCell>
                )}
                <TableCell>{p.region}</TableCell>
                <TableCell>{p.destination}</TableCell>
                <TableCell>{p.description}</TableCell>
                <TableCell>{p.max_passenger}</TableCell>
                <TableCell>{p.group_price}</TableCell>
                <TableCell>{p.passenger_price}</TableCell>
                {!props.selezionabile && (
                  <TableCell
                    style={{
                      textAlign: "right",
                    }}
                  >
                    <EditIcon
                      onClick={(e) => {
                        e.stopPropagation();
                        setInputRegione(p.region);
                        setInputDestinazione(p.destination);
                        setInputDescrizione(p.description);
                        setInputMaxPax(p.max_passenger);
                        setInputEuroPax(p.passenger_price);
                        setInputEuroGruppo(p.group_price);
                        setInputId(p.id);
                        setApriBoxNuovo(true);
                      }}
                      style={{ marginRight: "20px", cursor: "pointer" }}
                    />
                    <CopyIcon
                      color="warning"
                      style={{ marginRight: "20px", cursor: "pointer" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setInputId(0);
                        setInputRegione(p.region);
                        setInputDestinazione(p.destination);
                        setInputDescrizione(p.description);
                        setInputMaxPax(p.max_passenger);
                        setInputEuroPax(p.passenger_price);
                        setInputEuroGruppo(p.group_price);
                        setApriBoxNuovo(true);
                      }}
                    />
                    <DeleteIcon
                      color="error"
                      onClick={(e) => {
                        e.stopPropagation();
                        setPopupElimina(true);
                        setDaEliminare(p.id);
                      }}
                      style={{ marginRight: "20px", cursor: "pointer" }}
                    />
                  </TableCell>
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <br />
      <Pagination
        count={Math.ceil(totaleRis / risPerPagina)}
        page={pagina}
        onChange={(e, p) => {
          setPagina(p);
        }}
      />
      <Popup
        open={popupElimina}
        fnAnnulla={(e) => setPopupElimina(false)}
        fnSuccess={(e) => elimina(daEliminare)}
      />
    </>
  );
};

const Popup = (props) => {
  return (
    <Dialog open={props.open} onClose={props.fnAnnulla}>
      <DialogTitle>Conferma Eliminazione</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Confermi l'eliminazione dell'elemento?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.fnAnnulla}>Annulla</Button>
        <Button onClick={props.fnSuccess} variant="contained">
          Elimina
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Comp;
